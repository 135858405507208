module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"type":{"Tag":{"exclude":true},"PostFormat":{"exclude":true},"Comment":{"exclude":true},"Category":{"exclude":true},"Menu":{"exclude":true},"MenuItem":{"exclude":true},"UserRole":{"exclude":true},"ContentType":{"exclude":true},"Taxonomy":{"exclude":true},"MediaItem":{"createFileNodes":false,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"schema":{"perPage":50,"requestConcurrency":5,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"url":"https://troop.pottstownscouting.org/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BSA Troop 146, Pottstown PA","short_name":"BSA Troop 146","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"src/images/boy-scouts-logo-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d4b1ca41cb8b7c429b601c76f712da25"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
